import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "title-div" }
const _hoisted_2 = { class: "input" }
const _hoisted_3 = { class: "error" }
const _hoisted_4 = {
  key: 0,
  class: "edit-button"
}
const _hoisted_5 = { class: "edit-text" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = {
  action: "",
  class: "content-div",
  method: ""
}
const _hoisted_8 = { class: "details" }
const _hoisted_9 = { class: "container" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "container" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "details" }
const _hoisted_14 = { class: "container" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "container" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = { class: "details" }
const _hoisted_19 = { className: "flex justify-end w-full" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" }
const _hoisted_22 = { class: "bg-white rounded-xl w-[24rem] h-fit p-4" }
const _hoisted_23 = { class: "flex justify-between" }
const _hoisted_24 = { class: "cursor-pointer border border-gray-500 p-2 hover:bg-gray-300" }
const _hoisted_25 = { className: "mt-4 flex flex-col " }
const _hoisted_26 = { className: "my-4 flex justify-end w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["info-details", { border: _ctx.settings === true }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[9] || (_cache[9] = _createElementVNode("span", { class: "title" }, "Personal Information", -1)),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.detail)), 1)
        ]),
        (_ctx.settings === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "edit",
                style: {"cursor":"pointer"},
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveUserDetails()))
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.Save), 1),
                _cache[10] || (_cache[10] = _createElementVNode("svg", {
                  width: "15",
                  height: "14",
                  viewBox: "0 0 15 14",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M13.3603 0.195726C13.0996 -0.065242 12.6769 -0.0652421 12.4162 0.195726L11.8338 0.778677C11.0894 0.422698 10.1707 0.553319 9.55407 1.17054L2.47309 8.25841L6.24961 12.0386L13.3306 4.95074C13.9472 4.33352 14.0777 3.41396 13.7221 2.66878L14.3045 2.08583C14.5652 1.82486 14.5652 1.40174 14.3045 1.14078L13.3603 0.195726ZM10.511 5.88297L6.24961 10.1485L4.36135 8.25841L8.62275 3.99287L10.511 5.88297ZM11.7249 4.6679L12.3865 4.00569C12.6472 3.74472 12.6472 3.32161 12.3865 3.06064L11.4423 2.11559C11.1816 1.85462 10.7589 1.85462 10.4982 2.11559L9.83663 2.7778L11.7249 4.6679Z",
                    fill: "#0F172A",
                    "fill-opacity": "0.5"
                  }),
                  _createElementVNode("path", {
                    d: "M0.5 14L1.91646 8.80197L5.69272 12.5824L0.5 14Z",
                    fill: "#0F172A"
                  })
                ], -1))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("form", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[11] || (_cache[11] = _createElementVNode("p", { class: "content-text" }, "First name", -1)),
              _withDirectives(_createElementVNode("input", {
                disabled: _ctx.inputDisabled,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.firstName) = $event)),
                class: _normalizeClass({ border: _ctx.settings === true }),
                type: "text",
                name: "first-name",
                placeholder: "First Name"
              }, null, 10, _hoisted_10), [
                [_vModelText, _ctx.firstName]
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _cache[12] || (_cache[12] = _createElementVNode("p", { class: "content-text" }, "Last name", -1)),
              _withDirectives(_createElementVNode("input", {
                disabled: _ctx.inputDisabled,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lastName) = $event)),
                class: _normalizeClass({ border: _ctx.settings === true }),
                type: "text",
                name: "last-name",
                placeholder: "Last name"
              }, null, 10, _hoisted_12), [
                [_vModelText, _ctx.lastName]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[13] || (_cache[13] = _createElementVNode("p", { class: "content-text" }, "Email address", -1)),
              _withDirectives(_createElementVNode("input", {
                disabled: _ctx.inputDisabled,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.emailAddress) = $event)),
                class: _normalizeClass({ border: _ctx.settings === true }),
                type: "email",
                name: "email",
                placeholder: "Email Address"
              }, null, 10, _hoisted_15), [
                [_vModelText, _ctx.emailAddress]
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[14] || (_cache[14] = _createElementVNode("p", { class: "content-text" }, "Mobile number", -1)),
              _withDirectives(_createElementVNode("input", {
                disabled: _ctx.inputDisabled,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.mobileNumber) = $event)),
                class: _normalizeClass({ border: _ctx.settings === true }),
                type: "text",
                name: "phone-number",
                placeholder: "Mobile Number"
              }, null, 10, _hoisted_17), [
                [_vModelText, _ctx.mobileNumber]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("p", {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteAccountModal = true)),
                class: "text-red-500 cursor-pointer hover:underline text-sm"
              }, " Delete Accoiunt ")
            ])
          ])
        ])
      ])
    ], 2),
    (_ctx.deleteAccountModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[16] || (_cache[16] = _createElementVNode("h2", null, "Delete account modal", -1)),
                _createElementVNode("div", _hoisted_24, [
                  (_openBlock(), _createElementBlock("svg", {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.deleteAccountModal = false)),
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor",
                    class: "w-6 h-6 cursor-pointer"
                  }, _cache[15] || (_cache[15] = [
                    _createElementVNode("path", {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M6 18L18 6M6 6l12 12"
                    }, null, -1)
                  ])))
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _cache[17] || (_cache[17] = _createElementVNode("label", { for: "currentPassword" }, "Current Password", -1)),
                _withDirectives(_createElementVNode("input", {
                  placeholder: "Current Password",
                  name: "currentPassword",
                  class: "w-full h-[51px] py-4 px-6 mt-3 outline-none border border-solid rounded-[8px] bg-[#F3F3F3] flex flex-row items-center justify-between",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pw) = $event)),
                  label: "Current Password",
                  type: "text"
                }, null, 512), [
                  [_vModelText, _ctx.pw]
                ])
              ]),
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "my-10 text-small bg-red-100 rounded-md p-2 w-full" }, [
                _createElementVNode("p", null, [
                  _createElementVNode("b", null, "Note:"),
                  _createTextVNode(" This action is permanent and irreversible.")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("button", {
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleDeleteAccount && _ctx.handleDeleteAccount(...args))),
                  class: "bg-primary rounded-full px-4 py-2"
                }, " Delete Account ")
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}